import { $ } from '../utils/Utils';

require('es6-promise').polyfill();
const axios = require('axios');

const cision = (() => {
  const config = {
    endpoint: '',
    triggers: Array.from($('.js-pressreleases')),
    containerCN: 'js-pressreleases',
    itemContainerCN: 'pressList__items',
    itemCN: 'pressList__item',
    dateCN: 'pressList__date lead',
    titleCN: 'pressList__title display-2',
    btnCN: 'pressList__btn',
    drawCount: 5,
  };

  const convertDate = date => new Date(date).toLocaleDateString('sv-SE');

  const draw = data => {
    const innerContent = data.Releases.slice(0, config.drawCount)
      .map(
        item =>
          `<a class="${config.itemCN}" target="_new" href="${
            item.CisionWireUrl
          }"><span class="${config.dateCN}">${convertDate(
            item.PublishDate,
          )}</span><h3 class="${config.titleCN}">${item.Title}</h3></a>`,
      )
      .join('');
    document.querySelector(
      `.${config.itemContainerCN}`,
    ).innerHTML = innerContent;

    if (data.TotalFoundReleases > config.drawCount)
      document.querySelector(`.${config.btnCN}`).style.visibility = 'visible';
  };

  const doRequest = () => {
    axios
      .get(config.endpoint)
      .then(response => draw(response.data))
      .catch(() => {
        document.querySelector(`.${config.itemContainerCN}`).innerHTML = '';
      });
  };

  const init = () => {
    if (
      config.triggers.length > 0 &&
      config.triggers[0].getAttribute('data-endpoint').length > 0
    ) {
      config.endpoint = config.triggers[0].getAttribute('data-endpoint');
      doRequest();
    }
  };

  return {
    init,
  };
})();

export default cision.init();

export default {
  v: '5.5.8',
  fr: 60,
  ip: 0,
  op: 180,
  w: 463,
  h: 521,
  nm: 'P27_LayerAnimation',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Path 1099',
      sr: 1,
      ks: {
        o: { a: 0, k: 40, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [231.076, 260.119, 0],
              to: [0, 25.3, 0],
              ti: [0, -25.3, 0],
            },
            { t: 180, s: [231.076, 411.92, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [230.576, 107.728, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.397, -1.571],
                    [0, 0],
                    [4.684, 2.17],
                    [0, 0],
                    [-3.394, 1.571],
                    [0, 0],
                    [-4.956, -2.297],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-4.684, 2.17],
                    [0, 0],
                    [-3.394, -1.571],
                    [0, 0],
                    [4.956, -2.297],
                    [0, 0],
                    [3.397, 1.571],
                  ],
                  v: [
                    [458.605, 111.661],
                    [237.958, 213.83],
                    [223.192, 213.83],
                    [2.546, 111.661],
                    [2.546, 103.689],
                    [222.761, 1.723],
                    [238.389, 1.723],
                    [458.605, 103.689],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.360784313725, 0.537254901961, 0.788235294118, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path 1099',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Path 1097',
      sr: 1,
      ks: {
        o: { a: 0, k: 40, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0.211, y: 0 },
              t: 0,
              s: [231.076, 260.119, 0],
              to: [0, 12.65, 0],
              ti: [0, -12.65, 0],
            },
            { t: 180, s: [231.076, 336.02, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [230.576, 107.728, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.397, -1.571],
                    [0, 0],
                    [4.684, 2.17],
                    [0, 0],
                    [-3.394, 1.571],
                    [0, 0],
                    [-4.956, -2.297],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-4.684, 2.17],
                    [0, 0],
                    [-3.394, -1.571],
                    [0, 0],
                    [4.956, -2.297],
                    [0, 0],
                    [3.397, 1.571],
                  ],
                  v: [
                    [458.605, 111.661],
                    [237.958, 213.83],
                    [223.192, 213.83],
                    [2.546, 111.661],
                    [2.546, 103.689],
                    [222.761, 1.723],
                    [238.389, 1.723],
                    [458.605, 103.689],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.360784313725, 0.537254901961, 0.788235294118, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path 1097',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Path 1096',
      sr: 1,
      ks: {
        o: { a: 0, k: 40, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0.333, y: 0 },
              t: 0,
              s: [231.076, 260.119, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0],
            },
            { t: 180, s: [231.076, 260.119, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [230.576, 107.728, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.397, -1.571],
                    [0, 0],
                    [4.684, 2.17],
                    [0, 0],
                    [-3.394, 1.571],
                    [0, 0],
                    [-4.956, -2.297],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-4.684, 2.17],
                    [0, 0],
                    [-3.394, -1.571],
                    [0, 0],
                    [4.956, -2.297],
                    [0, 0],
                    [3.397, 1.571],
                  ],
                  v: [
                    [458.605, 111.661],
                    [237.958, 213.83],
                    [223.192, 213.83],
                    [2.546, 111.661],
                    [2.546, 103.689],
                    [222.761, 1.723],
                    [238.389, 1.723],
                    [458.605, 103.689],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.360784313725, 0.537254901961, 0.788235294118, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path 1096',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Path 1094',
      sr: 1,
      ks: {
        o: { a: 0, k: 40, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0.243, y: 0 },
              t: 0,
              s: [231.076, 260.119, 0],
              to: [0, -12.65, 0],
              ti: [0, 12.65, 0],
            },
            { t: 180, s: [231.076, 184.219, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [230.576, 107.728, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.397, -1.571],
                    [0, 0],
                    [4.684, 2.17],
                    [0, 0],
                    [-3.394, 1.571],
                    [0, 0],
                    [-4.956, -2.297],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-4.684, 2.17],
                    [0, 0],
                    [-3.394, -1.571],
                    [0, 0],
                    [4.956, -2.297],
                    [0, 0],
                    [3.397, 1.571],
                  ],
                  v: [
                    [458.605, 111.661],
                    [237.958, 213.83],
                    [223.192, 213.83],
                    [2.546, 111.661],
                    [2.546, 103.689],
                    [222.761, 1.723],
                    [238.389, 1.723],
                    [458.605, 103.689],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.360784313725, 0.537254901961, 0.788235294118, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path 1094',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Path 1091',
      sr: 1,
      ks: {
        o: { a: 0, k: 40, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0, y: 1 },
              o: { x: 0.215, y: 0 },
              t: 0,
              s: [231.076, 260.119, 0],
              to: [0, -25.3, 0],
              ti: [0, 25.3, 0],
            },
            { t: 180, s: [231.076, 108.319, 0] },
          ],
          ix: 2,
        },
        a: { a: 0, k: [230.576, 107.728, 0], ix: 1 },
        s: { a: 0, k: [100, 100, 100], ix: 6 },
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [3.397, -1.571],
                    [0, 0],
                    [4.684, 2.17],
                    [0, 0],
                    [-3.394, 1.571],
                    [0, 0],
                    [-4.956, -2.297],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-4.684, 2.17],
                    [0, 0],
                    [-3.394, -1.571],
                    [0, 0],
                    [4.956, -2.297],
                    [0, 0],
                    [3.397, 1.571],
                  ],
                  v: [
                    [458.605, 111.661],
                    [237.958, 213.83],
                    [223.192, 213.83],
                    [2.546, 111.661],
                    [2.546, 103.689],
                    [222.761, 1.723],
                    [238.389, 1.723],
                    [458.605, 103.689],
                  ],
                  c: true,
                },
                ix: 2,
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false,
            },
            {
              ty: 'st',
              c: {
                a: 0,
                k: [0.360784313725, 0.537254901961, 0.788235294118, 1],
                ix: 3,
              },
              o: { a: 0, k: 100, ix: 4 },
              w: { a: 0, k: 1, ix: 5 },
              lc: 1,
              lj: 1,
              ml: 4,
              bm: 0,
              nm: 'Stroke 1',
              mn: 'ADBE Vector Graphic - Stroke',
              hd: false,
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform',
            },
          ],
          nm: 'Path 1091',
          np: 3,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false,
        },
      ],
      ip: 0,
      op: 180,
      st: 0,
      bm: 0,
    },
  ],
  markers: [],
};

import tabbable from 'tabbable';

export default class FocusHandler {
  constructor(node) {
    this.node = node;
  }

  focusFirstFocusable(elementToFocus = false) {
    this.lastActiveElement = document.activeElement;
    if (elementToFocus) {
      elementToFocus.focus();
    } else {
      tabbable(this.node)[0].focus();
    }
  }

  focusLastActiveElement() {
    this.lastActiveElement.focus();
  }

  maintainFocus = event => {
    const tabbableElements = tabbable(this.node);

    if (!this.node.contains(document.activeElement)) {
      tabbableElements[0].focus();
    } else {
      const focusedItemIndex = tabbableElements.indexOf(document.activeElement);

      if (event.shiftKey && focusedItemIndex === 0) {
        tabbableElements[tabbableElements.length - 1].focus();
        event.preventDefault();
      }

      if (!event.shiftKey && focusedItemIndex === tabbableElements.length - 1) {
        tabbableElements[0].focus();
        event.preventDefault();
      }
    }
  };
}

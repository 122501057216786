import lottieweb from 'lottie-web';
import { $ } from '../utils/Utils';
import animationdata from '../../animations/hero-animation';

const lottie = (() => {
  const config = {
    triggers: Array.from($('.js-lottie')),
    containerCN: document.getElementById('heroAnimation'),
    name: 'hero-animation',
    autoplay: true,
    loop: false,
  };

  const build = () => {
    const params = {
      container: config.containerCN,
      renderer: 'svg',
      animationData: animationdata,
      loop: config.loop,
      autoplay: config.autoplay,
      name: config.name,
    };

    lottieweb.loadAnimation(params);
  };

  const init = () => {
    if (config.triggers.length > 0) {
      build();
    }
  };

  return {
    init,
  };
})();

export default lottie.init();

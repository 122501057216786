import { $ } from '../utils/Utils';

const accordion = (() => {
  const config = {
    triggers: Array.from($('.js-accordionContainer')),
    questionCN: 'js-accordionTrigger',
    answerCN: 'js-accordionTarget',
    parentCN: 'js-accordionParent',
    containerCN: 'js-accordionContainer',
    activeCN: '-active',
    showOneAnswerAtATime: true,
  };

  const addAttributes = (element, attr) => {
    Object.entries(attr).forEach(([k, v]) => {
      element.setAttribute(k, v);
    });
  };

  const handleAttributes = ({ container, parents, index }) => {
    parents.forEach((parent, i) => {
      // find the question of this item
      const question = parent.querySelector(`.${config.questionCN}`);
      // find the answer of this item
      const answer = parent.querySelector(`.${config.answerCN}`);
      // add the aria attributes on the question
      addAttributes(question, {
        id: `acc-desc-${index}${i}`,
        role: 'tab',
        'aria-controls': `acc-header-${index}${i}`,
        'aria-expanded': 'false',
        'aria-selected': 'false',
        tabindex: '-1',
      });
      // add the aria attributes on the answer
      addAttributes(answer, {
        id: `acc-header-${index}${i}`,
        role: 'tabpanel',
        'aria-labelledby': `acc-desc-${index}${i}`,
        'aria-hidden': 'true',
      });

      // Make first tab clickable
      if (i === 0) {
        addAttributes(question, { tabindex: '0' });
      }
    });

    // add attributes to container
    addAttributes(container, {
      role: 'tablist',
      'aria-multiselectable': 'true',
    });
  };

  // save question focus
  const saveFocus = ({ element, questions }) => {
    // Reset other tab attributes
    questions.forEach(question => {
      addAttributes(question, {
        tabindex: '-1',
        'aria-selected': 'false',
      });
    });

    // Set this tab attributes
    addAttributes(element, {
      tabindex: '0',
      'aria-selected': 'true',
    });
  };

  // show answer on click
  const showAnswer = ({ element, target, questions }) => {
    const question = element;
    const answer = target;

    saveFocus({ element: question, questions });

    if (answer.classList.contains(config.activeCN)) {
      // Hide answer
      answer.classList.remove(config.activeCN);
      question.classList.remove(config.activeCN);
      addAttributes(question, { 'aria-expanded': 'false' });
      addAttributes(answer, { 'aria-hidden': 'true' });
    } else {
      if (config.showOneAnswerAtATime) {
        answer.classList.remove(config.activeCN);
        question.classList.remove(config.activeCN);
        addAttributes(answer, { 'aria-hidden': 'true' });
        addAttributes(question, { 'aria-expanded': 'false' });
      }

      answer.classList.add(config.activeCN);
      question.classList.add(config.activeCN);
      addAttributes(question, { 'aria-expanded': 'true' });
      addAttributes(answer, { 'aria-hidden': 'false' });
    }
  };

  const handleKeyboard = ({
    parents,
    parentIndex,
    element,
    e,
    target,
    questions,
  }) => {
    const keyCode = e.which;
    const question = {
      current: element,
      first: parents[0].querySelector(`.${config.questionCN}`),
      last: parents[parents.length - 1].querySelector(`.${config.questionCN}`),
      next: parents[parentIndex + 1]
        ? parents[parentIndex + 1].querySelector(`.${config.questionCN}`)
        : false,
      previous: parents[parentIndex - 1]
        ? parents[parentIndex - 1].querySelector(`.${config.questionCN}`)
        : false,
    };

    // console.log(parentIndex);
    // console.log(question);

    switch (keyCode) {
      // Left/Up
      case 37:
      case 38:
        e.preventDefault();
        e.stopPropagation();

        // Check for previous question
        if (!question.previous) {
          // No previous, set focus on last question
          question.last.focus();
        } else {
          // Move focus to previous question
          question.previous.focus();
        }

        break;

      // Right/Down
      case 39:
      case 40:
        e.preventDefault();
        e.stopPropagation();

        // Check for next question
        if (!question.next) {
          // No next, set focus on first question
          question.first.focus();
        } else {
          // Move focus to next question
          question.next.focus();
        }

        break;

      // Home
      case 36:
        e.preventDefault();
        e.stopPropagation();

        // Set focus on first question
        question.first.focus();
        break;

      // End
      case 35:
        e.preventDefault();
        e.stopPropagation();

        // Set focus on last question
        question.last.focus();
        break;

      // Enter/Space
      case 13:
      case 32:
        e.preventDefault();
        e.stopPropagation();

        // Show answer content
        showAnswer({ element, target, questions });
        break;

      default:
        break;
    }
  };

  const handleListeners = ({ container, parents }) => {
    const questions = Array.from(
      container.querySelectorAll(`.${config.questionCN}`),
    );

    parents.forEach((parent, index) => {
      // find the question of this item
      const question = parent.querySelector(`.${config.questionCN}`);
      // find the answer of this item
      const answer = parent.querySelector(`.${config.answerCN}`);

      question.addEventListener('click', () =>
        showAnswer({ element: question, target: answer, questions }),
      );
      question.addEventListener('keydown', e =>
        handleKeyboard({
          parents,
          parentIndex: index,
          element: question,
          e,
          target: answer,
          questions,
        }),
      );
      question.addEventListener('focus', () =>
        saveFocus({ element: question, questions }),
      );
    });
  };

  const build = () => {
    // setup roles and initial properties
    config.triggers.forEach((container, index) => {
      // find parent in each container
      const parents = Array.from(
        container.querySelectorAll(`.${config.parentCN}`),
      );

      handleAttributes({ container, parents, index });
      handleListeners({ container, parents });
    });
  };

  const init = () => {
    if (config.triggers.length > 0) {
      build();
    }
  };

  return {
    init,
  };
})();

export default accordion.init();

import { $, freeze, handleESC } from '../utils/Utils';
import FocusHandler from '../utils/focus-handler';

/* This script deals with the opening/closing of the menu,
 * both on desktop and mobile.
 *
 */

const menu = (() => {
  const config = {
    hamburger: $('.js-menuTrigger')[0],
    checkbox: $('.js-menuEnabler')[0],
    target: Array.from($('.js-menuTarget')),
    dismiss: Array.from($('.js-menuDismiss')),
    focusHandler: [],
  };

  const handleTab = event => {
    if (event.key === 'Tab') {
      // keep in the focus within
      // check which menu is to be targeted
      config.focusHandler.desktop.maintainFocus(event);
    }
  };

  const disableTabWithin = () => {
    // Focus last element before opening the menu
    config.focusHandler.desktop.focusLastActiveElement();
    // stop maintaining focus
    document.removeEventListener('keydown', handleTab);
    // disable scroll freeze
    freeze.stop();
    // stop listening ESC pressing
    handleESC.stop(() => {
      // close menu
      const { checkbox } = config;
      checkbox.checked = false;
    });
  };

  const handleTabWithin = () => {
    // Focus on first element that is focusable
    // check which menu is to be targeted
    config.focusHandler.desktop.focusFirstFocusable();
    // maintain focus on tab
    document.addEventListener('keydown', handleTab);
    // freeze scroll
    freeze.start();
    // handle ESC button
    handleESC.init(disableTabWithin);
  };

  const handleMenu = () => {
    const { checkbox } = config;

    if (checkbox.checked) {
      handleTabWithin();
      // accessibility
      config.target.forEach(target => target.removeAttribute('aria-hidden'));
    } else {
      disableTabWithin();
      // accessibility
      config.target.forEach(target =>
        target.setAttribute('aria-hidden', 'true'),
      );
    }
  };

  const toggleMenu = e => {
    const { checkbox } = config;
    if (e.key === 'Enter') {
      checkbox.checked = !checkbox.checked;
      handleMenu();
    }
  };

  const build = () => {
    // hamburger is a styled <label> and doesn't allow keyboard triggering
    // we're adding a listener on keydown to help triggering
    config.hamburger.addEventListener('keydown', e => toggleMenu(e));
    // same for the dismiss button in the modal
    config.dismiss.forEach(dismiss => {
      dismiss.addEventListener('keydown', e => toggleMenu(e));
    });

    config.checkbox.addEventListener('click', e => handleMenu(e));

    // keep in mind that they are two menu, one for mobile, one for desktop
    // data-target will have the value "mobile" or "desktop"
    config.target.forEach(target => {
      const key = target.dataset.target.toLowerCase();
      config.focusHandler[key] = new FocusHandler(target);
    });
  };
  const init = () => {
    if (config.target.length > 0) {
      build();
    }
  };

  return {
    init,
  };
})();

menu.init();

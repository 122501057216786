// add required core-js polyfills
import 'js-polyfills/dom';
import 'js-polyfills/html';
import 'core-js/features/array';
import 'core-js/features/object';
import 'classlist-polyfill';
import 'es6-promise';
import AOS from 'aos';

import 'svgxuse';
import './components/menu';
import './components/accordion';
import './components/cision';
import './components/lottie';
import './components/toggle';

AOS.init();

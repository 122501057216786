$(document).ready(function() {
    if($('.toggle-text-inner').height() < 1000){ 
        $('.toggle-text-button').addClass('d-none');
    }
  $('.toggle-text-button').click(function(){
      var openheight = $(this).parent().find('.toggle-text').height();
      if($(this).hasClass('toggle-text-open')){
          $(this).removeClass('toggle-text-open');
          $(this).parent().find('.toggle-text-inner').animate({'max-height':1000},500);
      }
      else {
          $(this).addClass('d-none');
          $('.text-button-up').addClass('d-block');
          $(this).parent().find('.toggle-text-inner').animate({'max-height':5000},500);
      }
  });
  $('.text-button-up').click(function(){
      $(this).removeClass('d-block');
      $('.toggle-text-inner').animate({'max-height':1000},500);
      $('.toggle-text-button').removeClass('d-none');
  });
});
// querySelector, jQuery style
export const $ = selector => document.querySelectorAll(selector);

export const isMobile = () => {
  const answer = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent,
  )
    ? !0
    : !1;
  return answer;
};

export const baseURL = '/app/themes/p27/dist/';

export const freeze = (() => {
  const freezeCN = 'freeze';
  const html = document.querySelector('html');

  const start = () => {
    html.classList.add(freezeCN);
  };
  const stop = () => {
    html.classList.remove(freezeCN);
  };

  return {
    start,
    stop,
  };
})();

export const handleESC = (() => {
  const stop = (callback = false) => {
    window.onkeyup = null;

    if (callback !== false) {
      callback();
    }
  };

  const init = (callback = false) => {
    window.onkeyup = e => {
      switch (
        e.which // ESC
      ) {
        case 27:
          stop();
          if (callback !== false) {
            callback();
          }
          break;
        default:
          break;
      }
    };
  };

  return {
    init,
    stop,
  };
})();

export const siblings = (() => {
  const find = ({ filter = '', sibling = document.body } = {}) => {
    let n = sibling.parentNode.firstElementChild;
    const arr = [];

    // eslint-disable-next-line no-cond-assign
    while ((n = n.nextElementSibling)) {
      if (filter !== '') {
        // remove . or # in selector
        const fCN = filter.replace(/\./g, '');
        const fID = filter.replace(/#/g, '');
        // check if matches ID or class
        if (
          n.classList.value.toLowerCase().indexOf(fCN.toLowerCase()) > -1 ||
          n.id.toLowerCase().indexOf(fID.toLowerCase()) > -1
        ) {
          arr.push(n);
        }
      } else {
        arr.push(n);
      }
    }
    return arr;
  };

  return {
    find,
  };
})();

export const findParent = (element, parent) => {
  let nodeList = element.parentNode;
  let a = 0;

  while (nodeList) {
    if (nodeList === document) {
      break;
    }
    if (nodeList.classList.contains(parent)) {
      return nodeList;
    }
    a += a;
    nodeList = nodeList.parentNode;
  }

  return document;
};

export const findTarget = ({ element, parent, target }) =>
  findParent(element, parent).querySelector(`.${target}`);

export const findTargets = ({ element, parent, target }) =>
  findParent(element, parent).querySelectorAll(`.${target}`);

export const formFieldsInside = {
  performSelection: (element, exclude) => {
    // get all form fields
    const elements = [
      ...element.querySelectorAll('input'),
      ...element.querySelectorAll('textarea'),
      ...element.querySelectorAll('select'),
    ];
    // exclude elements from the selection
    exclude.forEach(ex => {
      const index = elements.indexOf(ex);
      if (index > -1) {
        elements.splice(index, 1);
      }
    });
    return elements;
  },
  enable(element, exclude = []) {
    const elements = this.performSelection(element, exclude);
    // remove disabled attribute on selected form fields
    elements.forEach(field => {
      field.removeAttribute('disabled');
    });
  },
  disable(element, exclude = []) {
    const elements = this.performSelection(element, exclude);
    // set disabled attribute on selected form fields
    elements.forEach(field => {
      field.setAttribute('disabled', 'true');
    });
  },
};

export const loading = {
  config: {
    classname: '-loading',
  },
  show(t, which = '') {
    if (t.length && t.length > 1) {
      // if t is an array, perform the same function for each item
      t.forEach(item => {
        this.show(item);
      });
    } else {
      const target = t;
      // remove old results from target ?
      target.innerHTML = '';
      // show loading
      target.classList.add(`${loading.config.classname}${which}`);
    }
  },
  stop(t, which = '') {
    if (t.length && t.length > 1) {
      // if t is an array, perform the same function for each item
      t.forEach(item => {
        this.stop(item);
      });
    } else {
      const target = t;
      target.classList.remove(`${loading.config.classname}${which}`);
    }
  },
};

export const dataExist = (obj, key) =>
  obj[key] !== undefined && key in obj && obj[key] !== '';

export const shorten = (str, maxLen, separator = ' ') => {
  if (str.length <= maxLen) return str;
  return `${str.substr(0, str.lastIndexOf(separator, maxLen))}…`;
};

export const generateID = () =>
  `_${Math.random()
    .toString(36)
    .substr(2, 9)}`;

export const deleteItem = item => item.parentNode.removeChild(item);

export const ww = () => window.innerWidth;

export const excludedKey = event => {
  let answer = true;

  switch (event.key) {
    case 'Tab':
    case 'Meta':
    case 'Shift':
    case 'Control':
    case 'Escape':
    case ' ':
    case 'CapsLock':
    case 'Alt':
    case 'Enter':
    case 'ArrowRight':
    case 'ArrowLeft':
    case 'ArrowDown':
    case 'ArrowUp':
    case 'F1':
    case 'F2':
    case 'F3':
    case 'F4':
    case 'F5':
    case 'F6':
    case 'F7':
    case 'F8':
    case 'F9':
    case 'F10':
    case 'F11':
    case 'F12':
      answer = false;
      break;
    default:
      break;
  }

  return answer;
};

export const svg = (icon, classnames) =>
  `<svg class="icon -${icon} ${classnames}" aria-hidden="true"><use xlink:href="${baseURL}/icons/sprite.svg#${icon}"/></svg>`;

export const importer = {
  url: url =>
    new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = url;
      script.addEventListener('load', () => resolve(script), false);
      script.addEventListener('error', () => reject(script), false);
      document.body.appendChild(script);
    }),
  urls: urls => Promise.all(urls.map(importer.url)),
};

export const escapeRegExp = str => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

export const translationGuard = str => {
  // First add a *lowercase* word you *don't want to translate* to the lexicon
  // if you add some words here, you'll have to do the same in macros.twig
  const lexicon = ['öbo', 'rosta'];
  // Let's create variants of that word: uppercase, first letter uppercase
  lexicon.forEach(word => {
    // Set Uppercase
    lexicon.push(word.toUpperCase());
    // Capitilize first letter
    lexicon.push(word.charAt(0).toUpperCase() + word.slice(1));
  });

  // Create replace expressions for each words
  let newStr = str;
  lexicon.forEach(word => {
    // Adding <span class="notranslate"></span> will prevent google from translating the word
    newStr = newStr.replace(
      new RegExp(escapeRegExp(word), 'g'),
      `<span class="notranslate">${word}</span>`,
    );
  });

  return newStr;
};

/*eslint-disable */
export const getClosest = (elem, selector) => {
  // Element.matches() polyfill
  if (!Element.prototype.matches) {
    Element.prototype.matches =
      Element.prototype.matchesSelector ||
      Element.prototype.mozMatchesSelector ||
      Element.prototype.msMatchesSelector ||
      Element.prototype.oMatchesSelector ||
      Element.prototype.webkitMatchesSelector ||
      function(s) {
        let matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i = matches.length;
        while (--i >= 0 && matches.item(i) !== this) {}
        return i > -1;
      };
  }

  // Get closest match
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }

  return null;
};
/* eslint-enable */

export const isGALoaded = () =>
  new Promise((resolve, reject) => {
    // create a promise to check if ga (google analytics) is loaded and available
    let counter = 0;
    const interval = 500; // in ms
    const intertvalLimit = 60; // in s
    const FormattedLimit = (1000 / interval) * intertvalLimit; // in real s, adjusted to the interval

    const checkGA = setInterval(() => {
      if (typeof ga === 'function') {
        // ga is loaded and available
        // stop the interval
        clearInterval(checkGA);
        // send resolution from promise
        resolve(true);
      } else {
        // add to counter
        counter += 1;
        // check if we have reached the set limit
        if (counter >= FormattedLimit) {
          // too many attempts to check if GA is available
          // let's stop to check
          clearInterval(checkGA);
          // send error from promise
          reject(
            new Error(
              "Couldn't not verify if google anylitcs variable (ga) is available",
            ),
          );
        }
      }
    }, interval);
  });
